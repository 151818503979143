<template>
  <div>
    <div class="d-flex justify-content-center col-12 text-center">
      <head-banner />
    </div>
    <div class="d-flex justify-content-center col-12 text-center">
      <add-here />
    </div>
    <b-row class="match-height mb-1 pt-2">
      <b-col lg="12">
        <h3>Today Events</h3>
      </b-col>
    </b-row>
    <b-row class="match-height mb-1">
      <b-col lg="12">
        <crypto-table
          :table-data="todayCalenderData"
          :table_name="'calender'"
          :total="todayCalenderData.length"
        />
      </b-col>
    </b-row>
    <full-calendar
      :events="calenderData"
      ref="calendar"
      @event-selected="eventSelected"
    ></full-calendar>
  </div>
</template>

<script>
import { FullCalendar } from "vue-full-calendar";
import "fullcalendar/dist/fullcalendar.css";
import CryptoTable from "./crypto/CryptoTable.vue";
import { BRow, BCol } from "bootstrap-vue";
import AddHere from "@/@core/components/AddHere.vue";
import HeadBanner from "@/@core/components/carousel/HeadBanner.vue";
import { mixinList } from "@/mixins/mixinList";

export default {
  mixins: [mixinList],
  components: {
    FullCalendar,
    CryptoTable,
    BRow,
    BCol,
    AddHere,
    HeadBanner
  },
  data() {
    return {};
  },
  mounted() {
    this.fetchEventData();
  },
  methods: {
    eventSelected(val) {
      this.$router.push({
        path: `/details/${val.id}`,
        params: {
          id: val.id,
        },
      });
    },
    fetchEventData() {
      this.$store.dispatch("FETCH_CLIENT_IP").then(() => {
        this.$store.dispatch("FETCH_CALENDER_ALL_EVENTS", 20);
        this.$store.dispatch("FETCH_CALENDER_TODAY_EVENTS", 20);
      });
    },
  },
  computed: {
    calenderData() {
      return this.$store.state.crypto.calenderData;
    },
    todayCalenderData() {
      return this.$store.state.crypto.todayCalenderData;
    },
  },
};
</script>
<style>
[dir] .fc-unthemed th,
[dir] .fc-unthemed td,
[dir] .fc-unthemed thead,
[dir] .fc-unthemed tbody,
[dir] .fc-unthemed .fc-divider,
[dir] .fc-unthemed .fc-row,
[dir] .fc-unthemed .fc-content,
[dir] .fc-unthemed .fc-popover,
[dir] .fc-unthemed .fc-list-view,
[dir] .fc-unthemed .fc-list-heading td {
  border-color: #ddd;
  font-family: inherit;
  font-weight: bold;
}
</style>